/*video {
    object-fit: cover;
    width: 100%;
    height: calc(100vh - 80px);
    position: fixed;
    z-index: -1;
}*/
.intro {
  min-height: calc(100vh);
  background: url('./img-home1.jpeg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100%;
}

.intro-container {
  min-height: calc(100vh - 80px);
  /*background-image: linear-gradient(30deg, rgba(250, 250, 250, .2) 33%, rgba(0, 0, 0, .7) 33%);*/
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  object-fit: contain;
  padding-top: 20%;
}

.intro-container > h3 {
  color: #fff;
  text-align: center;
  font-size: 54px;
  text-shadow: 3px 3px 3px #000;
}

.intro-container > h1 {
  margin-top: 20px;
  font-size: 32px;
  color: #fff;
  text-shadow: 3px 3px 3px #000;
}

.intro-btns {
  margin-top: 25px;
  padding: 20px;
  border-radius: 5px;
  opacity: 1;
  cursor: pointer;
}

.services-wrap {
  border: 4px solid #fff;
  box-shadow: 2px 2px 2px #000;
}

.contact-wrap {
  border: 4px solid #fff;
  box-shadow: 1px 1px 2px #000;
}

.start-btn {
  text-decoration: none;
  padding: 20px;
  font-weight: 700;
  font-size: 26px;
  text-shadow: 3px 3px 3px #000;
  color: #fff;
}

.contact-btn {
  text-decoration: none;
  padding: 40px;
  font-weight: 700;
  font-size: 26px;
  text-shadow: 3px 3px 3px #000;
  color: #fff;
}

@media screen and (max-width: 480px) {
  .intro {
    margin-top: -60px;
    min-height: calc(100vh);
  }

  .intro-container {
    min-height: calc(100vh);
    background-color: rgba(0, 0, 0, 0.4);
  }

  .intro-bg {
    display: none;
  }

  .intro-bg-mobile {
    display: block;
    background: center cover / center;
  }

  .intro-container > h3 {
    font-size: 34px;
    margin-top: 35%;
  }

  .intro-container > h1 {
    margin-top: 15px;
    font-size: 22px;
  }

  .btns-mobile-pos {
    margin-top: 10px;
  }

  .intro-btns {
    border-radius: 0;
    background-color: rgba(0, 0, 0, 0.6);
    border-radius: 999px;
    border: 2px solid #fff;
    margin-top: 15px;
    text-align: center;
  }

  .start-btn {
    padding: 10px;
    text-decoration: none;
    width: 100%;
    font-size: 22px;
    color: #fff;
    text-shadow: 1px 1px 1px rgb(0, 0, 0);
  }

  .contact-btn {
    padding: 10px;
    text-decoration: none;
    font-size: 22px;
    color: #fff;
    text-shadow: 1px 1px 1px rgb(0, 0, 0);
  }
}
