.contact {
  padding: 4rem;
  background-color: #242424;
  margin: 0 auto;
}

.contact h1 {
  color: #fff;
  padding-bottom: 50px;
}

.contact-container {
  margin: 0 auto;
  max-width: 1120px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.container {
  width: 85%;
  background: #fff;
  border-radius: 6px;
  padding: 20px 60px 30px 40px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}
.container .content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.container .content .left-side {
  width: 25%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
  position: relative;
}
.content .left-side::before {
  content: "";
  position: absolute;
  height: 70%;
  width: 2px;
  right: -15px;
  top: 50%;
  transform: translateY(-50%);
  background: #afafb6;
}
.content .left-side .details {
  margin: 14px;
  text-align: center;
}
.content .left-side .details i {
  font-size: 30px;
  color: #000;
  margin-bottom: 10px;
}
.content .left-side .details .topic {
  font-size: 18px;
  font-weight: 500;
}
.content .left-side .details .text-one,
.content .left-side .details .text-two {
  font-size: 14px;
  color: #7e7e81;
}
.container .content .right-side {
  width: 75%;
  margin-left: 75px;
}
.content .right-side .topic-text {
  font-size: 23px;
  font-weight: 600;
  color: #000;
}
.right-side .input-box {
  height: 50px;
  width: 100%;
  margin: 12px 0;
}
.right-side .input-box input,
.right-side .input-box textarea {
  height: 100%;
  width: 100%;
  border: none;
  outline: none;
  font-size: 16px;
  background: #f0f1f8;
  border-radius: 6px;
  padding: 0 15px;
  resize: none;
}
.right-side .message-box {
  min-height: 110px;
}
.right-side .input-box textarea {
  padding-top: 6px;
}
.right-side .button {
  display: inline-block;
  margin-top: 12px;
}
.right-side .button input[type="button"] {
  color: #fff;
  font-size: 18px;
  text-align: center;
  outline: none;
  border: none;
  padding: 8px 16px;
  border-radius: 6px;
  background: #000000;
  cursor: pointer;
  transition: all 0.3s ease;
}
.button input[type="button"]:hover {
  background: rgb(39, 39, 39);

}

@media (max-width: 950px) {
  .container {
    width: 90%;
    padding: 30px 40px 40px 35px;
  }
  .container .content .right-side {
    width: 75%;
    margin-left: 55px;
  }
}
@media (max-width: 820px) {
  .container {
    margin: 40px 0;
    height: 100%;
  }
  .container .content {
    flex-direction: column-reverse;
  }
  .container .content .left-side {
    width: 100%;
    flex-direction: row;
    margin-top: 40px;
    justify-content: center;
    flex-wrap: wrap;
  }
  .container .content .left-side::before {
    display: none;
  }
  .container .content .right-side {
    width: 100%;
    margin-left: 0;
  }
}

.con-fab {
    padding-left: 10px;
    padding-right: 10px;
}

@media (max-width: 480px) {
  .contact {
    padding: 1rem;
  }

  .contact h1 {
      padding-top: 50px;
    padding-bottom: 40px;
  }

  .container {
    margin: 0;
  }
}
