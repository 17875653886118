@import "~react-image-gallery/styles/css/image-gallery.css";

.gallery {
  padding: 3rem;
  background: #fff;
}

.gallery-header {
  padding-bottom: 40px;
}

.gallery h1 {
  color: #000;
}

.gallery-container {
  align-items: center;
  max-width: 1120px;
  min-height: 100vh;
  width: 90%;
  margin: 0 auto;
}

@media only screen and (max-width: 480px) {
  .gallery {
    padding: 1rem;
    padding-top: 50px;
    background: #fff;
  }
  .gallery-header {
    background: #242424;
    margin: 20px 15px 0 15px;
    padding: 15px 0 15px 0;
    margin-bottom: 30px;
  }

  .gallery-header h1 {
    color: #fff;
    padding-left: 30px;
    padding-right: 30px;
  }
}
