.about {
  padding: 4rem;
  background: #242424;
  color: #fff;
}

.about-container {
  display: flex;
  flex-flow: column;
  align-items: center;
  max-width: 1120px;
  width: 90%;
  margin: 0 auto;
  padding-top: 80px;
}

h1 {
  text-align: center;
}

.about-wrapper {
  position: relative;
  margin: 50px 0 45px;
}

.about-items {
  margin-bottom: 24px;
  display: flex;
}

.about-item {
  display: flex;
  flex: 1;
  margin: 0 1rem;
  padding-bottom: 50px;
  border-radius: 10px;
}

.about-item-link {
  display: flex;
  flex-flow: row;
  width: 100%;
}

.about-item-link i {
  font-size: 36px;
  padding-right: 20px;
}

.about-item-text {
  font-size: 18px;
  line-height: 24px;
}

.about-item-text i {
  font-size: 36px;
  padding-top: 5px;
}

.trbtn {
  text-align: center;
  padding-top: 50px;
  padding-bottom: 50px;
}

.mobile {
  display: none;
}

.desktop {
  display: block;
}

.trbtn a {
  color: #fff;
  text-decoration: none;
  font-size: 18px;
  font-weight: 600;
  padding: 20px;
  border: 3px solid #fff;
  border-radius: 5px;
  transition: 0.8s;
  cursor: pointer;
}

.trbtn a:hover {
  font-size: 19.5px;
  top: 0;
  left: 0;
}

.rule-container {
  display: none;
  transition: all 0.5s ease;
}

.rule-wrap {
  max-width: 1120px;
  background: #242424;
  padding: 2rem;

}

.rule-wrap h3 {
  padding-top: 20px;
  padding-bottom: 20px;
}

.rule-btn {
  text-align: right;
  position: sticky;
}

.rule-text {
  text-align: justify;
}

.rule-container.active-rule {
  display: none;
  transition: all 2s ease;
  z-index: 999999;
}

@media only screen and (max-width: 480px) {
  .about {
    padding: 0;
  }

  .about-header {
    padding-top: 40px;
  }

  .about-items {
    display: block;
    margin-bottom: 0px;
  }

  .about {
    padding: 2rem;
  }

  .about-wrapper {
    position: relative;
    margin: 0;
  }

  .about-item {
    padding-bottom: 40px;
  }

  .rule-container {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 99999;
    transition: all 0.5s ease;
  }

  .rule-wrap {
    position: absolute;
    top: 0;
    left: 0;
  }
  
  .rule-wrap {
    max-width: 1120px;
    background: #242424;
    padding: 2rem;
    position: absolute;
    top: 0;
    left: 0;
  }

  .mobile {
    display: block;
  }
  
  .desktop {
    display: none;
  }
  
}
